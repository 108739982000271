import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getReceives: createCancelTokenHandler('getReceives'),
  getReceiveActivitiesLog: createCancelTokenHandler('getReceiveActivitiesLog')
}
function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getReceives (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/receive${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getReceives'].handleRequestCancellation().token
  })
}
export function printReceive ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/receive?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getReceive (id) {

  return axios({
    url: `v1/admin/treasury/receive/${id}`,
    method: 'get'
  })
}

export function getReceiveActivitiesLog (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/receive/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getReceiveActivitiesLog'].handleRequestCancellation().token
  })
}

export function confirmReceive (id, payment) {

  return axios({
    url: `v1/admin/treasury/receive/${id}/confirm`,
    data: payment,
    method: 'post'
  })
}

export function insertReceive (payment) {

  return axios({
    url: 'v1/admin/treasury/receive',
    data: payment,
    method: 'post'
  })
}

export function deleteReceive (id) {
  return axios({
    url: `v1/admin/treasury/receive/${id}`,
    method: 'delete'
  })
}
